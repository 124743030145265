import { Component, Input } from '@angular/core';
import { AssetItem, Maybe } from '@designage/gql';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';
import { AssetContentFormComponent } from '../asset-content-form/asset-content-form.component';

@Component({
  standalone: true,
  imports: [FormDialogComponent, AssetContentFormComponent],
  selector: 'app-add-asset-content-dialog',
  template: `<app-form-dialog
    closeButtonText="OK"
    dismissButtonText="CANCEL"
    [valid]="isFormValid"
    [pristine]="isFormPristine"
    headerText="ADD_CONTENT"
    [values]="value"
  >
    <app-asset-content-form
      [multiSelect]="multiSelect"
      [filterMediaTypes]="filterMediaTypes"
      (valid)="isFormValid = $event"
      (pristine)="isFormPristine = $event"
      (value)="value = $event"
    ></app-asset-content-form>
  </app-form-dialog> `,
})
export class AddAssetContentDialogComponent {
  @Input() multiSelect = true;
  @Input() filterMediaTypes = '';

  isFormValid = false;
  isFormPristine = true;
  loaderMessage!: string;
  loading = true;
  value!: Maybe<AssetItem>;
}
