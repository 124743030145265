<form [formGroup]="actionFormGroup">
  <h5>&nbsp;</h5>
  <!-- name -->
  <div class="mb-3">
    <label for="type" class="form-label">
      {{ 'TYPE' | translate }}
    </label>
    <select
      class="form-control form-control-dark form-select"
      aria-label="Select action"
      formControlName="type"
    >
      <option selected value="PLAY_VIDEO">Play Video</option>
    </select>
  </div>

  <hr />

  <!-- video -->
  <div class="mb-3 d-flex flex-column">
    <label class="form-label" for="backgroundColor">{{
      'VIDEO' | translate
    }}</label>
    <app-media-thumbnail-by-id
      class="media-thumbnail"
      *ngIf="target"
      [mediaId]="target"
    ></app-media-thumbnail-by-id>

    <button
      type="button"
      class="m-2 border btn btn-sm btn-outline-primary border-primary"
      ngbTooltip="{{ 'SELECT' | translate }}"
      (click)="onSelectContent(); $event.stopPropagation()"
    >
      {{ 'SELECT' | translate }}
    </button>
  </div>
</form>
