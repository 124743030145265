import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  AssetType,
  Maybe,
  MediaDetailedFragment,
  ResourceType,
} from '@designage/gql';
import { IZoneResolution } from '@desquare/interfaces';
import { MediaService } from '@desquare/services';
import { getOptimizedUrl } from '@desquare/utils';

@Component({
  standalone: true,
  imports: [NgIf],
  selector: 'app-media-thumbnail-by-id',
  template: `<img *ngIf="showPreview" class="preview" [src]="thumbnailUrl" /> `,
  styles: [
    `
      .preview {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    `,
  ],
})
export class MediaThumbnailByIdComponent implements OnInit {
  media: Maybe<MediaDetailedFragment>;

  _mediaId: Maybe<string>;
  @Input()
  set mediaId(value: Maybe<string>) {
    this._mediaId = value;
    this.getMedia(value);
  }
  get mediaId() {
    return this._mediaId;
  }

  get showPreview() {
    return (
      this.media?.type === ResourceType.Image ||
      this.media?.type === ResourceType.Video
    );
  }

  constructor(private mediaService: MediaService) {}

  ngOnInit(): void {}

  async getMedia(id: Maybe<string>) {
    this.media = id ? await this.mediaService.getMediaById(id) : null;
  }

  private getUri(resolution?: IZoneResolution): Maybe<string> {
    if (!this.media) return '';
    switch (this.media.type) {
      case ResourceType.Video: {
        const videoThumbnail = `${this.uri?.substring(
          0,
          this.uri.lastIndexOf('.')
        )}.webp`;
        return getOptimizedUrl(AssetType.Image, videoThumbnail, resolution);
      }
      case ResourceType.Image: {
        /*
        const resizeCropMethod =
          this.assetItem.__typename === 'ImageAsset'
            ? this.assetItem.resizeCropMethod?.uri
            : ResizeCropMethodURIs.PAD_URI;
            */

        return getOptimizedUrl(
          AssetType.Image,
          this.media.secureUrl,
          resolution
        ); //resizeCropMethod
      }

      default:
        return null;
    }
  }

  get uri() {
    return this.media?.secureUrl || '';
  }
  get thumbnailUrl() {
    if (!this.media) return;

    return this.getUri({
      resolution: '',
      width: 0,
      height: 200,
      aspect: 0,
      orientation: '',
      default: false,
    });
  }
}
