import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { CustomFeatures, Maybe } from '@designage/gql';
import { LayoutType } from '@desquare/enums';
import { ICloudinaryUploadResponse, ISmilRegion } from '@desquare/interfaces';
import { CurrentUserService, LayoutEditorService } from '@desquare/services';
import { NgbActiveModal, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import { LayoutSettingsComponent } from '../layout-settings/layout-settings.component';
import { LayoutRegionSettingsComponent } from '../layout-region-settings/layout-region-settings.component';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    NgbNavModule,
    LayoutSettingsComponent,
    LayoutRegionSettingsComponent,
  ],
  selector: 'app-layout-form',
  template: `<div class="h-100 row">
    <div class="d-flex">
      <!-- creative editor -->
      <div #ce_container class="creative-editor"></div>

      <!-- inspector -->
      <!-- TODO here: we can breakdown the inspector component further later -->
      <div class="inspector">
        <div class="container-fluid content-container-card">
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-pills">
            <!-- Layout detail settings -->
            <li [ngbNavItem]="1">
              <a ngbNavLink>Layout Details</a>
              <ng-template ngbNavContent>
                <app-layout-settings
                  [parentFormGroup]="parentFormGroup"
                  [isManageMode]="isManageMode"
                ></app-layout-settings>
              </ng-template>
            </li>

            <!-- Region block settings -->
            @if(showRegions){
            <li [ngbNavItem]="2">
              <a ngbNavLink>{{ SCREEN_REGIONS | translate }}</a>
              <ng-template ngbNavContent>
                <app-layout-region-settings
                  [parentFormGroup]="parentFormGroup"
                  [isManageMode]="isManageMode"
                ></app-layout-region-settings>
              </ng-template>
            </li>
            }
          </ul>

          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div> `,
  styleUrls: ['./layout-form.component.scss'],
})
export class LayoutFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();
  @Input() parentFormGroup?: FormGroup;
  @Input() isManageMode = false;
  @Input() editorSourceBlob: Maybe<string>;
  @Input() backgroundUrl: Maybe<string>;
  @Input() height: Maybe<number>;
  @Input() width: Maybe<number>;

  @Input() layoutType = LayoutType.Standard;

  @Output() submitForm = new EventEmitter();

  @ViewChild('ce_container') containerRef: ElementRef = {} as ElementRef;

  regionBlocks: ISmilRegion[] = [];
  activeTab: number = 1;
  layoutEditorFormGroup!: FormGroup;
  saveSceneResponse?: ICloudinaryUploadResponse;

  showRegions = false;

  get isInteractiveLayout() {
    return this.layoutEditorService.layoutType === LayoutType.Interactive;
  }

  /* dynamic labels */
  SCREEN_REGIONS = 'SCREEN_REGIONS';

  constructor(
    public editorModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private layoutEditorService: LayoutEditorService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit() {
    this.initForm();
    this.showRegions = this.currentUserService.isFeatureEnabled(
      CustomFeatures.LayoutRegions
    );
  }

  async ngAfterViewInit() {
    await this.initCreativeEditor();

    // load scene to creative editor
    await this.loadSceneBlob();

    this.initFormSubscription();
    if (this.isInteractiveLayout) {
      this.activeTab = 2;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initFormSubscription() {
    this.subs.sink =
      this.layoutEditorFormGroup.controls.sceneUrl.valueChanges.subscribe(
        async (value) => {
          // so far from the tests, this only triggers on the next changes after
          // the initial load

          // console.log('scene url changed: ', value); // DEBUG

          // load scene to creative editor
          await this.loadSceneBlob();
        }
      );
  }

  async initCreativeEditor() {
    await this.layoutEditorService.initCreativeEditor(
      this.containerRef,
      undefined,
      this.layoutType
    );
  }

  initForm() {
    const FORM_GROUP_NAME = 'layoutSourceForm';

    this.layoutEditorFormGroup =
      (this.parentFormGroup?.controls[FORM_GROUP_NAME] as FormGroup) ??
      this.formBuilder.group({
        sceneUrl: '',
        cloudinaryPublicId: '',
        regionBlocks: this.regionBlocks,
      });

    if (this.parentFormGroup) {
      this.parentFormGroup.addControl(
        FORM_GROUP_NAME,
        this.layoutEditorFormGroup
      );
    }
  }

  async loadSceneBlob() {
    if (!this.isManageMode) return;

    if (this.layoutType === LayoutType.Interactive) {
      if (this.editorSourceBlob?.length) {
        await this.layoutEditorService.loadFromString(this.editorSourceBlob);
      } else {
        // create a canvas of proper size
        if (this.height && this.width)
          this.layoutEditorService.updateCanvas({
            width: this.width,
            height: this.height,
          });
      }
      if (this.backgroundUrl) {
        this.layoutEditorService.createBackgroundBlock(this.backgroundUrl);
      }
    } else {
      const sceneUrl = this.layoutEditorFormGroup.controls.sceneUrl.value;

      if (sceneUrl === '') return console.error('no sceneUrl value');

      await this.layoutEditorService.loadSceneFromCloudinary(sceneUrl);
    }
    // console.log('loaded scene: ', loadedScene); // DEBUG
  }
}
