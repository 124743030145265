import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AddAssetContentDialogComponent } from '@designage/app/asset/add-asset-content-dialog/add-asset-content-dialog.component';
import { Maybe, ResourceType } from '@designage/gql';
import { IRegionAction } from '@desquare/interfaces';
import { LayoutEditorService } from '@desquare/services';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { v4 as uuidV4 } from 'uuid';
import { TranslateModule } from '@ngx-translate/core';
import { MediaThumbnailByIdComponent } from '../../shared/media-thumbnail-by-id/media-thumbnail-by-id.component';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MediaThumbnailByIdComponent,
    NgbTooltip,
    TranslateModule,
  ],
  selector: 'app-action-settings',
  templateUrl: './action-settings.component.html',
  styleUrls: ['./action-settings.component.scss'],
})
export class ActionSettingsComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() action: Maybe<IRegionAction>;

  @Output() actionChange = new EventEmitter<Maybe<IRegionAction>>();

  @Input() isManageMode = false;
  @Input() parentFormGroup?: FormGroup;
  actionFormGroup!: FormGroup;
  // this is for easy access like using the form controls in the HTML template
  get formControls() {
    return this.actionFormGroup.controls;
  }

  get target() {
    return this.action?.target || null;
    // return this.formControls.target?.value;
  }
  set target(value: string | null) {
    if (this.action) {
      this.action.target = value ? value : undefined;
    }

    this.formControls.target.setValue(value);
  }

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private layoutEditorService: LayoutEditorService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initForm() {
    const FORM_GROUP_NAME = 'action';

    const newAction = {
      id: uuidV4(),
      type: 'PLAY_VIDEO',
      target: '',
    };

    // use present action or a new one
    this.actionFormGroup =
      (this.parentFormGroup?.controls[FORM_GROUP_NAME] as FormGroup) ??
      this.formBuilder.group(newAction);

    if (this.parentFormGroup) {
      this.parentFormGroup.addControl(FORM_GROUP_NAME, this.actionFormGroup);
    }
  }

  initSubscriptions() {
    // TODO: manage action this way but with metadata in block
    /*
    // get only the first value
    this.subs.sink = this.layoutEditorService.canvas$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return (
            prev?.width === curr?.width &&
            prev?.height === curr?.height &&
            prev?.color === curr?.color
          );
        })
      )
      .subscribe((value) => {
        if (value !== null) {
          const { width, height, color } = value;

          this.actionFormGroup.patchValue(
            {
              width,
              height,
              color,
            },
            { emitEvent: false }
          );
        }
      });

    

    // update canvas height when form height changes
    this.subs.sink = this.formControls.height.valueChanges.subscribe(
      (value) => {
        this.layoutEditorService.updateCanvas({ height: value });
      }
    );
*/
  }

  /** choose video */
  onSelectContent() {
    // this.playlistEditorService.closePickers.emit();

    const modalRef = this.modalService.open(AddAssetContentDialogComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modalRef.componentInstance.multiSelect = false;
    modalRef.componentInstance.filterMediaTypes = ResourceType.Video;

    modalRef.result.then(
      async ({ media }) => {
        this.target = media.id;
        /*
        const action: IRegionAction = {
          id: this.actionFormGroup.controls.id.value,
          type: this.actionFormGroup.controls.type.value,
          target: media.id,
        };*/
        this.actionChange.emit(this.action);
      },
      () => {}
    );
  }
}
